import React, { useState } from 'react'
import { logo_swiftech, logo_swiftech_short } from '../constant/files'
import { navigation } from '../constant/arrays'
import { NavLink } from 'react-router-dom'

const Header = () => {

    const [bg, setBg] = useState(false)

    window.addEventListener("scroll",()=>{
        if (window.scrollY > 30) {
            setBg(true)
        } else {
            setBg(false)
        }
    })

    return (
        <>
            <header id='navId' className={'mb-3 py-2 fixed-top top-0 ' + (bg?'transparent-bg':'')}>
                <div className='container'>
                    <div className='row align-items-center justify-content-between'>
                        <NavLink to={'/'} onClick={()=>window.scrollTo({top: 0, left: 0, behavior: 'instant' })} className=' col-auto' style={{"height":"60px"}}>
                            <img src={logo_swiftech} alt='HUTY' className=' img-fluid object-fit-contain h-100' />
                        </NavLink>
                        <div className='col-auto ms-auto d-flex justify-content-end'>
                            <ul className=' list-unstyled d-md-flex d-none'>
                                {
                                    navigation.map((elt, index) =>(
                                        <li key={index} className=' text-uppercase fw-medium' ><NavLink className=' text-white fs-6 text-decoration-none mx-3' to={elt.link} onClick={()=>window.scrollTo({top: 0, left: 0, behavior: 'instant' })} > {elt.nav} </NavLink></li>
                                    ))
                                }
                            </ul>
                            <div role='button' className=' d-md-none' data-bs-toggle="offcanvas" href="#subNav">
                                <i className=' fa fa-bars text-white fs-3'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


            <div className="offcanvas offcanvas-start subnav-bg" tabindex="-1" id="subNav" aria-labelledby="subNavLabel">
                <div className="offcanvas-header">
                    <div className=' d-flex align-items-center mb-3'>
                        <img src={logo_swiftech_short} alt='logo swiftech' className=' img-fluid' style={{height:"45px"}} />
                        <h2 className=' fw-bold mb-0 mt-3 color-primary'>SWIFTECH Inc</h2>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <ul className=' list-unstyled'>
                        {
                            navigation.map((elt, index) =>(
                                <li key={index} className='text-uppercase fw-medium my-3 ms-3' data-bs-dismiss="offcanvas" >
                                    <NavLink className=' text-white fs-5 text-decoration-none' to={elt.link} onClick={()=>window.scrollTo({top: 0, left: 0, behavior: 'instant' })} > {elt.nav} </NavLink>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Header
