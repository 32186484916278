import React, { useEffect } from "react";

// Import styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import './asset/vendor/fontawesome/css/all.min.css'
import './asset/vendor/remixicon/remixicon.css'
import "react-multi-carousel/lib/styles.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'aos/dist/aos.css'
import './asset/style/style.css'


import Aos from "aos";
import Header from "./components/Header";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import Footer from "./components/Footer";
import Services from "./views/Services";
import About from "./views/About";
import Contact from "./views/Contact";



function App() {

  useEffect(()=>{
    Aos.init()
  },[])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/'>
          <Route index element={<Home/>} />
          <Route path="service/" element={<Services/>} />
          <Route path="about" element={<About/>} />
          <Route path="contact" element={<Contact/>} />
        </Route>
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
