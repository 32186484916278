import React from 'react'
import { logo_swiftech_short } from '../constant/files'
import { NavLink } from 'react-router-dom'
import { navigation, servicesArray } from '../constant/arrays'
import { email, localisation, localisationLink, phoneNumber, phoneNumberFormat } from '../constant/const'

const Footer = () => {
    return (
        <footer className=' footer-bg pt-3'>
            <div className=' py-5 mt-5 container'>
                <div className=' row justify-content-around align-items-start text-white text-opacity-75'>


                    <div className=' col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
                        <div>
                            <div className=' d-flex align-items-center mb-3'>
                                <img src={logo_swiftech_short} alt='logo swiftech' className=' img-fluid' style={{height:"45px"}} />
                                <h2 className=' fw-bold mb-0 mt-3 color-primary'>SWIFTECH INC.</h2>
                            </div>
                            <span className=''>Sécurité, performance, SWIFTECH: Connectés au succès.</span>
                            <div className=' d-flex align-items-center mt-4'>
                                <i className=' fa fa-phone-square fs-5 color-primary'></i>
                                <a className='text-white text-opacity-75 ms-2' href={"tel:"+phoneNumber}>{phoneNumberFormat}</a>
                            </div>
                            <div className=' d-flex align-items-center mt-2'>
                                <i className=' fa fa-envelope fs-5 color-primary'></i>
                                <a className='text-white text-opacity-75 ms-2' href={'mailto:'+email}>{email}</a>
                            </div>
                            <div className=' d-flex align-items-center mt-2'>
                                <i className=' fa fa-location-dot fs-5 color-primary'></i>
                                <a className='text-white text-opacity-75 ms-2' target='_BLANK' rel='noreferrer' href={localisationLink}>{localisation}</a>
                            </div>
                        </div>
                    </div>



                    <div className=' col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
                        <div className=' mt-2'>
                            <h2 className=' fw-bold text-white'>Liens utiles</h2>
                            <ul className=' list-unstyled'>
                                {
                                    navigation.map((elt,index) =>(
                                        <li key={index} className=' mb-2'><NavLink to={elt.link} className=' text-white text-opacity-75 fw-medium'>{elt.nav} </NavLink></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>



                    <div className=' col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
                        <div className=' mt-2'>
                            <h2 className=' fw-bold text-white'>Nos solutions</h2>
                            <ul className=' list-unstyled'>
                                {
                                    servicesArray.map((elt, index) =>(
                                        <li className=' mb-2' key={index}><NavLink to={"/service#"+elt.slug} className=' text-white text-opacity-75 fw-medium'>{elt.titleU + " " + elt.titleD} </NavLink></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>



                    <div className=' col-lg-3 col-md-6 col-12 mt-lg-0 mt-4'>
                        <div className=' mt-2'>
                            <h2 className=' fw-bold text-white'>Suivez nous</h2>
                            
                            <div className=' row justify-content-start align-items-center gx-3 pt-4'>
                                <div className=' col-auto'>
                                    <a href='https://www.linkedin.com/company/swiftech-cm/' className=' bgcolor-primary text-white d-flex justify-content-center align-items-center rounded-circle shadow' style={{width:"40px",height:"40px"}}>
                                        <i className=' fa-brands fa-facebook-f fs-5'></i>
                                    </a>
                                </div>
                                <div className=' col-auto'>
                                    <a href='https://www.instagram.com/swiftech_cm/' className=' bgcolor-primary text-white d-flex justify-content-center align-items-center rounded-circle shadow' style={{width:"40px",height:"40px"}}>
                                        <i className=' fa-brands fa-instagram fs-5'></i>
                                    </a>
                                </div>
                                <div className=' col-auto'>
                                    <a href='https://x.com/swiftech_cm' className=' bgcolor-primary text-white d-flex justify-content-center align-items-center rounded-circle shadow' style={{width:"40px",height:"40px"}}>
                                        <i className=' fa-brands ri-twitter-x-fill fs-5'></i>
                                    </a>
                                </div>
                                <div className=' col-auto'>
                                    <a href='https://www.linkedin.com/company/swiftech-cm/' className=' bgcolor-primary text-white d-flex justify-content-center align-items-center rounded-circle shadow' style={{width:"40px",height:"40px"}}>
                                        <i className=' fa-brands fa-linkedin-in fs-5'></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' bg-dark'>
                <div className=' container py-3 text-center'>
                    <smal className=' text-white text-opacity-75 fs-9s'>Copyright &copy; 2024 Swiftech by <a href='https://xonetech.cm' target='_BLANK'>Xone</a> Tout Droits Reservés</smal>
                </div>
            </div>
        </footer>
    )
}

export default Footer
