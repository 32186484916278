import React, { useEffect } from 'react'
import Title from '../components/Title'
import { testimonialsArray } from '../constant/arrays'
import TestimonialCard from '../components/TestimonialCard'
import Carousel from 'react-multi-carousel'

// assets vendor
import '../asset/vendor/css/fontawesome.css'
import '../asset/vendor/css/swiftech.css'
import '../asset/vendor/css/owl.css'
import '../asset/vendor/css/style.css'
import { description_1, description_2, matouke_img, ngomsi_img, ntoko_img } from '../constant/files'
import Aos from 'aos'
// import '../asset/vendor/jquery/jquery.min'
// import '../asset/vendor/js/custom.js'
// import '../asset/vendor/js/owl.js'
// import '../asset/vendor/js/slick.js'
// import '../asset/vendor/js/accordions.js'

const About = () => {

    useEffect(()=>{
        Aos.init()
      },[])

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div>
            
            {/* Page heading */}
            <div className="page-heading header-text apropos">
                <div className="container">
                <div data-aos-duration="700" data-aos="fade-up" className="row">
                    <div className="col-md-12">
                    <h1>A propos de nous</h1>
                    <h3>A propos de Swiftech</h3>
                    {/* <span>We have over 20 years of experience</span>  */}
                    </div>
                </div>
                </div>
            </div>


            <div className="more-info about-info">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="more-info-content">
                                <div className="row">
                                    <div data-aos-duration="900" data-aos="zoom-in-right" className="col-md-6 align-self-center">
                                        <div className="right-content">
                                        <span>Notre expertise en matiere d'informatique</span>
                                        <h2>En savoir plus sur <em> <br/>Notre Entreprise</em></h2>
                                        <p> Bienvenue chez Swiftech Inc, votre partenaire de confiance pour des solutions informatique innovantes et sur mesure. Forts de notre expertise et de notre passion pour les technologies de l'information, nous sommes déterminés a accompagner votre entreprise dans sa transformations numérique et a accompagner votre entreprise dans sa transformation numérique et a répondre a vos besoins informatiques les plus complexes.
                                            {/*<p>massah cest dure</p> */}
                                            <br/><br/>Que ce soit pour la gestion de l'infrastructure, le développement d'application, la cybersécurité, le cloud computing ou tout autre besoin en matière de technologies de l'information, nous sommes là pour vous accompagner à chaque étape. Notre engagement envers l'innovation et notre souci du détail font de nous le partenaire idéal pour propulser votre entreprise vers le succès numérique.
                                        </p>
                                        {/*<a href="" className="filled-button">Read More</a> */}
                                        </div>
                                    </div>
                                    <div data-aos-duration="900" data-aos="zoom-in-left" className="col-md-6">
                                        <div className="left-image">
                                        <img src={description_1} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>

            <div className="fun-facts">
                <div className="container">
                <div className="row">
                    <div className="col-md-6">
                    <div data-aos-duration="900" data-aos="fade-right" className="left-content">
                        {/*<span>Lorem ipsum dolor sit amet</span> */}
                        <h2>Nos solutions pour la <em>croissance de votre entreprise</em></h2>
                        <p>Nous avons réalisé plus de 10 projets avec succès ; offrant 8 solutions pour résoudre vos problèmes. Notre équipe de 13 employés dévoues cumule plus de 1000 heures de travail, prête à vous fournir un service de qualité exceptionnelle.
                        <br/><br/> Faites-nous confiance pour concrétiser votre projet.
                        </p>
                        {/*<a href="" className="filled-button">Read More</a> */}
                    </div>
                    </div>
                    <div className="col-md-6 align-self-center">
                    <div className="row">
                        <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">1000</div>
                            <div className="count-title">Heures de travail</div>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">13</div>
                            <div className="count-title">employés</div>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">10</div>
                            <div className="count-title">Projet réalisé</div>
                        </div>
                        </div>
                        <div className="col-md-6">
                        <div className="count-area-content">
                            <div className="count-digit">8</div>
                            <div className="count-title">Solution </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="more-info about-info">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <div className="more-info-content">
                        <div className="row">
                            <div data-aos-duration="900" data-aos="fade-up" className="col-md-6 align-self-center">
                                <div className="right-content">
                                <span><b>Faites un peu connaissance</b></span>
                                <h2>Avec nos <em>5 derniers projets</em></h2>
                                <p> Swiftech a récemment réalisé plusieurs projets informatiques majeurs, démontrant son engagement envers le succès de ses clients. Ces projets comprennent une transformation cloud pour une entreprise financière, l'automatisation des processus de production pour un fabricant, le déploiement de solutions de gestion des données pour un réseau hospitalier, la migration vers une plateforme cloud pour une entreprise, et le support IT proactif pour maintenir la stabilité des systèmes informatiques. Chaque projet illustre l'expertise holistique de Swiftech et son approche personnalisée pour répondre aux défis variés et évolutifs des clients dans un environnement numérique dynamique.
                                    {/*<p>massah cest dure</p> */}
                                    <br/><br/>Chacun de ces projets démontre notre capacité à répondre aux besoins variés des clients africains en matière de technologie, de sécurité et d'efficacité opérationnelle. Contactez-nous pour découvrir comment nous pouvons également transformer votre entreprise grâce à nos solutions innovantes.
                                </p>
                                {/*<a href="" className="filled-button">Read More</a> */}
                                </div>
                            </div>
                            <div data-aos-duration="900" data-aos="zoom-out-left" className="col-md-6">
                                <div className="left-image">
                                <img src={description_2} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>


            <div className="main-banner header-text" id="">
                <div className="Modern-Slider">
                    <div className="item item-1">
                        <div className="img-fill">
                        <div className="text-content">
                            <h6>Mise en place d'une solution de gestion de la relation client (CRM)</h6>
                            <h4>Client : ASAV INC.<br/> Durée : 3 mois</h4>
                            <p>Mise en place d'une solution de gestion de la relation client (CRM) personnalisée pour optimiser les ventes et améliorer l'expérience client.</p>
                        </div>
                        </div>
                    </div>
                    <div className="item item-2">
                        <div className="img-fill">
                        <div className="text-content">
                            <h6>Déploiement d'une infrastructure cloud sécurisée</h6>
                            <h4>Client : AUDITIX<br/> Durée : 4 mois</h4>
                            <p>Déploiement d'une infrastructure cloud sécurisée pour permettre une collaboration efficace entre les équipes et garantir la disponibilité des données en tout temps.</p>
                        </div>
                        </div>
                    </div>
                    <div className="item item-3">
                        <div className="img-fill">
                        <div className="text-content">
                            <h6>Intégration d'un système de gestion des ressources humaines</h6>
                            <h4>Client : ATURIUM<br/> Durée : 5 mois</h4>
                            <p>Intégration d'un système de gestion des ressources humaines (HRIS) pour simplifier les processus de gestion du personnel et améliorer la productivité de l'entreprise.</p>
                        </div>
                        </div>
                    </div>
                    <div className="item item-4">
                        <div className="img-fill">
                        <div className="text-content">
                            <h6>Fiailité et efficacité</h6>
                            <h4>Client : XONE<br/> Durée : 2 mois</h4>
                            <p>Développement d'une application mobile sur mesure pour offrir des services innovants et atteindre un public plus large, stimulant ainsi la croissance de l'entreprise.</p>
                        </div>
                        </div>
                    </div>
                    <div className="item item-5">
                        <div className="img-fill">
                        <div className="text-content">
                            <h6>Mise en œuvre d'une solution de sécurité informatique complète</h6>
                            <h4>Client : Institution Financiere<br/> Durée : 6 mois</h4>
                            <p>Mise en œuvre d'une solution de sécurité informatique complète pour protéger les données sensibles et assurer la conformité aux normes réglementaires.</p>
                        </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="team">
                <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div data-aos-duration="900" data-aos="fade-up" className="section-heading">
                            <h2>Les membres de notre <em>équipe</em></h2>
                            <span>Notre équipe comprends actuellement 3 Membres à la direction</span>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos-duration="900" data-aos="zoom-in" className="team-item">
                            <img src={matouke_img} alt=""/>
                            <div className="down-content rounded-0">
                                <h4>Matouke Thomas</h4>
                                <span>Fondateur & PDG</span>
                                <h6>fondateur de Swiftech, est un expert renommé en sécurité informatique. Avec une expérience approfondie dans le domaine, il a dirigé Swiftech vers l'excellence en développant des solutions de sécurité informatique innovantes et robustes. Sa vision stratégique et son leadership ont permis à Swiftech de devenir un acteur majeur dans le secteur, offrant à ses clients une protection avancée contre les menaces numériques et une tranquillité d'esprit en matière de cybersécurité.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos-duration="900" data-aos="zoom-in" className="team-item">
                            <img src={ngomsi_img} alt=""/>
                            <div className="down-content rounded-0">
                                <h4>Ngomsi Nolan</h4>
                                <span>Directeur technique</span>
                                <h6>Ngomsi Nolan est le co-fondateur de SWIFTECH. Il possède un fort intérêt pour les nouvelles technologies et l'innovation. Son rôle clé est d'améliorer les technologies actuelles et de développer des innovations qui renforcent la position de l'entreprise sur le marché IT. </h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div data-aos-duration="900" data-aos="zoom-in" className="team-item">
                            <img src={ntoko_img} alt=""/>
                            <div className="down-content rounded-0">
                                <h4>Ntoko Charles</h4>
                                <span>Directeur marketing</span>
                                <h6>Fort d'une expertise solide dans le domaine du marketing, il supervise la stratégie de communication et de promotion de l'entreprise. Passionné par l'innovation et les nouvelles tendances, il œuvre pour positionner l’entreprise en tant que leader sur son marché.</h6>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>


            {/* TESTIMONIALS */}
            <div className=' mt-4 pt-5 container mb-5 pb-5'>
                <Title center={true} title={"La Voix des Clients : Découvrez Ce Qu'ils Disent"} subtitle={"Des clients satisfaits sont notre meilleure publicité. Lisez leurs témoignages et rejoignez-les !"} />
                <div className="row mt-4 pt-3 d-md-flex d-none">
                    {
                        testimonialsArray.map((elt, index)=>(
                            <TestimonialCard key={index} text={elt.text} name={elt.name} identity={elt.identity} img={elt.img} />
                        ))
                    }
                </div>

                <div className=' mx-auto d-md-none'>
                    <Carousel className=' py-5' responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} >
                        {
                            testimonialsArray.map((elt, index)=>(
                                <TestimonialCard key={index} text={elt.text} name={elt.name} identity={elt.identity} img={elt.img} />
                            ))
                        }
                    </Carousel>
                </div>
            </div>
        </div>
    )
}

export default About