import { acces_internet_img, communication_entreprise_img, integration_erp_img, interconnexion_site_distant_img, maintenance_infogerance_img, reseau_informatique_img, securute_informatique_img, solution_cloud_img, userP, video_surveillance_img } from "./files"


// Navigation
export const navigation = [
    {nav:"Accueil", link:"/"},
    {nav:"Nos Solutions", link:"/service"},
    {nav:"A propos", link:"/about"},
    {nav:"Contact", link:"/contact"},
]


export const servicesArray =[
    {
        icon:"fa fa-shield-halved",
        slug:"securite-informatique", 
        titleU:"Sécurité ", 
        titleD:"Informatique", 
        text:"Protégez votre entreprise contre les menaces informatiques croissantes avec nos services de sécurité informatique de pointe.",
        img: securute_informatique_img,
        paragraph:["Notre offre comprend une suite complète de services et de solutions pour protéger les données et les systèmes de l'entreprise. Cela inclut la mise en place de pare-feu avancés, de solutions de détection des intrusions, de cryptage des données, de gestion des identités et des accès, de sauvegardes régulières et de plan de reprise après sinistre.","De plus, notre offre comprend également des services de sensibilisation à la sécurité pour former les employés aux bonnes pratiques en matière de sécurité informatique. Nous proposons également des audits de sécurité réguliers pour identifier les vulnérabilités et les points faibles du système, ainsi que des services de surveillance en temps réel pour détecter et répondre aux menaces potentielles."],
        paragraph2:"En choisissant notre offre de sécurité informatique, vous bénéficierez d'une protection complète contre les cyber-menaces et d'une tranquillité d'esprit quant à la sécurité de vos données et de vos systèmes."
    },
    {
        icon:"fa fa-tower-broadcast",
        slug:"interconnexion-site-distants", 
        titleU:"Interconnexion De ", 
        titleD:"Sites Distants", 
        text:"Établissez une connexion réseau fluide et sécurisée entre vos bureaux, succursales et partenaires.",
        img: interconnexion_site_distant_img,
        paragraph:["Notre offre de service pour l'interconnexion de sites distants par VPN est conçue sur mesure pour répondre aux besoins spécifiques de votre entreprise. Nous proposons une solution VPN sécurisée et fiable pour connecter vos différents sites distants de manière transparente et efficace.","Notre service comprend la configuration et la gestion complète du VPN, en utilisant des protocoles de chiffrement avancés pour garantir la confidentialité et l'intégrité des données transitant entre les sites. Nous assurons également la surveillance en temps réel du réseau VPN pour détecter et répondre rapidement à toute anomalie ou menace potentielle."],
        paragraph2:"En choisissant notre offre, votre entreprise bénéficiera d'une connectivité sécurisée entre ses sites distants, permettant un partage rapide et sécurisé des données, une collaboration efficace entre les équipes et une optimisation des processus métier. Avec notre solution VPN, vous pouvez être assuré que vos communications sensibles sont protégées et que vos opérations sont sécurisées et rapides."
    },
    {
        icon:"fa fa-network-wired",
        slug:"reseaux-informatique", 
        titleU:"Réseaux ", 
        titleD:"Informatique", 
        text:"Concevez, installez et gérez des réseaux informatiques robustes et performants pour soutenir votre entreprise.",
        img: reseau_informatique_img,
        paragraph:["Notre proposition de service comprend une gamme complète de services pour assurer la mise en place, la gestion et la maintenance efficace des réseaux informatiques de votre entreprise. Nous commençons par une analyse approfondie de vos besoins et de votre infrastructure existante pour concevoir une solution personnalisée qui répond à vos exigences spécifiques.","Nous assurons la mise en place professionnelle de votre réseau informatique, en installant et configurant les équipements réseau, en déployant des solutions de sécurité avancées et en optimisant les performances du réseau pour garantir une connectivité fiable et rapide.","Une fois le réseau mis en place, notre équipe de techniciens qualifiés assure la gestion continue du réseau, en surveillant les performances, en effectuant des mises à jour régulières, en gérant les problèmes de sécurité et en fournissant un support technique en cas de besoin.","Enfin, notre service de maintenance proactive garantit que votre réseau informatique reste opérationnel en tout temps, en identifiant et en résolvant les problèmes potentiels avant qu'ils ne deviennent des incidents majeurs."],
        paragraph2:"En choisissant notre proposition de service pour la mise en place, la gestion et la maintenance des réseaux informatiques, votre entreprise bénéficiera d'une infrastructure réseau fiable, sécurisée et performante, vous permettant de vous concentrer sur le développement de votre activité en toute tranquillité d'esprit."
    },
    {
        icon:"fa fa-globe",
        slug:"fourniture-acces-internet", 
        titleU:"Fourniture D'Accès ", 
        titleD:"Internet", 
        text:"Choisissez parmi une gamme de connexions Internet pour répondre à vos besoins en matière de bande passante et de budget.",
        img: acces_internet_img,
        paragraph:["Service de Fourniture d'Accès Internet est votre solution tout-en-un pour une connectivité domestique de qualité. Nous vous offrons un service complet comprenant la fourniture d'accès Internet haut débit et l'installation d'IPTV pour une expérience multimédia immersive à domicile.","Fourniture d'Accès Internet: Profitez d'une connexion Internet rapide et fiable grâce à notre service de fourniture d'accès Internet. Que vous ayez besoin d'une connexion haut débit pour le travail, les loisirs en ligne ou le streaming, nous vous proposons des forfaits adaptés à vos besoins.","Installation d'IPTV: Découvrez un divertissement en haute définition directement dans votre salon avec notre service d'installation d'IPTV. Profitez d'une large gamme de chaînes de télévision, de programmes à la demande et de services interactifs pour une expérience télévisuelle exceptionnelle."],
        paragraph2:"Que vous souhaitiez rester connecté, travailler à distance, regarder vos émissions préférées ou jouer en ligne, Home Connect vous offre la connectivité dont vous avez besoin pour profiter pleinement de votre domicile."
    },
    {
        icon:"fa fa-cloud-arrow-up",
        slug:"solutions-cloud", 
        titleU:"Solutions ", 
        titleD:"Cloud", 
        text:"Migrez vos données et vos applications vers le cloud pour une flexibilité, une évolutivité et une réduction des coûts.",
        img: solution_cloud_img,
        paragraph:["Notre solution Cloud une offre complète pour répondre à vos besoins en matière d'infrastructure cloud. Nous vous accompagnons à chaque étape, de la conception architecturale à la formation des utilisateurs, pour garantir une transition fluide vers le cloud.","Conception Architecturale Nous commençons par une analyse approfondie de vos besoins et de votre infrastructure existante pour concevoir une architecture cloud sur mesure. Notre équipe d'experts en cloud computing vous propose des solutions adaptées à vos objectifs et à votre budget.","Création'Instance Cloud: Nous prenons en charge la création et la configuration des instances cloud en fonction de vos spécifications. Que vous ayez besoin de serveurs virtuels, de stockage en ligne ou d'autres ressources cloud, nous nous assurons que tout est mis en place de manière optimale.","Configuration des Instances: Nous gérons la configuration et la personnalisation de vos instances cloud pour garantir des performances optimales et une sécurité renforcée. Nous veillons à ce que vos applications et données soient correctement déployées dans l'environnement cloud.","Surveillance des Actifs: Nous assurons une surveillance continue de vos actifs cloud pour détecter les problèmes potentiels et garantir une disponibilité maximale. Notre équipe réagit rapidement aux incidents et optimise les performances de votre infrastructure cloud."],
        paragraph2:"Avec notre service Cloud Connect, vous bénéficiez d'une expertise technique de haut niveau et d'un accompagnement personnalisé pour une transition réussie vers le cloud. Contactez-nous dès aujourd'hui pour en savoir plus sur nos services et commencer votre voyage vers le cloud."
    },
    {
        icon:"fa fa-comments",
        slug:"communication-entreprise", 
        titleU:"Communication ", 
        titleD:"D'Entreprise", 
        text:"Améliorez la communication et la collaboration au sein de votre entreprise avec nos solutions de communication unifiées.",
        img: communication_entreprise_img,
        paragraph:["Notre service Communication offre une gamme complète de solutions pour renforcer votre présence en ligne et optimiser vos communications digitales. Nous proposons la fourniture et la conception de sites web personnalisés, l'optimisation par les moteurs de recherche, des adresses mail professionnelles, des solutions de E-commerce et un design UI/UX de qualité.","Fourniture et Conception de Site Web Personnalisé: Nous concevons des sites web sur mesure qui reflètent l'identité de votre entreprise et captivent vos visiteurs. Notre équipe de designers et développeurs travaille en étroite collaboration avec vous pour créer un site web unique, attractif et fonctionnel, adapté à vos besoins spécifiques.","Optimisation par les Moteurs de Recherche (SEO): Nous mettons en place des stratégies de référencement naturel pour améliorer la visibilité de votre site web sur les moteurs de recherche. Grâce à des techniques SEO avancées, nous vous aidons à attirer un trafic qualifié et à augmenter votre présence en ligne.","Adresse Mail Professionnelle: Profitez d'adresses mail professionnelles personnalisées pour renforcer la crédibilité de votre entreprise. Nous vous proposons des solutions d'emailing sécurisées et fiables pour une communication professionnelle efficace avec vos clients et partenaires.","Solution de E-commerce: Développez votre activité en ligne avec notre solution de E-commerce complète. Nous vous aidons à créer une boutique en ligne performante, intuitive et sécurisée, pour vendre vos produits et services de manière efficace et rentable.","Design UI/UX de Qualité: Nous mettons l'accent sur l'expérience utilisateur en proposant un design UI/UX de qualité pour vos interfaces web. Nous optimisons la convivialité et l'esthétique de votre site pour offrir une expérience utilisateur fluide et agréable à vos visiteurs."],
        paragraph2:"Avec notre service Communication, vous disposez de toutes les solutions nécessaires pour renforcer votre présence en ligne et optimiser vos communications digitales. Contactez-nous dès aujourd'hui pour découvrir comment notre service peut booster votre activité en ligne."
    },
    {
        icon:"fa fa-video",
        slug:"video-surveillance", 
        titleU:"Vidéo ", 
        titleD:"Surveillance", 
        text:"Surveillez vos locaux en temps réel avec des caméras de sécurité haute définition.",
        img: video_surveillance_img,
        paragraph:["Service de Surveillance offre une tranquillité d'esprit totale en matière de sécurité pour votre entreprise ou votre domicile. Nous proposons une gamme complète de solutions, comprenant l'installation de vidéosurveillance, la configuration de la vision à distance et l'installation de contrôle d'accès pour protéger vos biens et assurer la sécurité de vos locaux.","Installation de Vidéosurveillance: Nous mettons en place un système de vidéosurveillance complet pour surveiller en temps réel vos espaces intérieurs et extérieurs. Grâce à des caméras haute définition et des enregistreurs performants, vous pouvez avoir un contrôle total sur ce qui se passe chez vous ou dans votre entreprise, même à distance.","Configuration de Vision à Distance: Avec notre service de configuration de vision à distance, vous pouvez accéder aux images de votre système de vidéosurveillance depuis n'importe où et à tout moment. Que vous soyez en déplacement ou à l'autre bout du monde, vous pouvez garder un œil sur vos locaux et réagir rapidement en cas d'incident.","Installation de Contrôle d'Accès: Nous proposons des solutions de contrôle d'accès avancées pour restreindre l'entrée à vos locaux et garantir la sécurité de vos employés et de vos biens. Que ce soit par le biais de cartes d'accès, de codes PIN ou de lecteurs biométriques, nous vous aidons à mettre en place un système de contrôle d'accès efficace et sécurisé."],
        paragraph2:"Avec notre service de Surveillance, vous pouvez avoir l'esprit tranquille en sachant que vos locaux sont sécurisés et surveillés en permanence. Contactez-nous dès aujourd'hui pour en savoir plus sur nos solutions de sécurité et protéger ce qui compte le plus pour vous."
    },
    {
        icon:"fa fa-screwdriver-wrench",
        slug:"maintenance-infogerance", 
        titleU:"Maintenance Et ", 
        titleD:"Infogérance", 
        text:"Bénéficiez d'une surveillance proactive et d'une résolution rapide des problèmes.",
        img: maintenance_infogerance_img,
        paragraph:["Cette offre est conçue pour offrir à votre entreprise une gestion complète et proactive de votre infrastructure informatique. Nous prenons en charge la maintenance régulière de vos systèmes, la surveillance continue de la performance, la résolution des incidents et la gestion des mises à jour logicielles et matérielles.","En tant que partenaires, nous assurons une disponibilité maximale de vos systèmes informatiques, en anticipant les problèmes potentiels et en mettant en place des mesures préventives pour garantir la stabilité et la sécurité de votre environnement informatique."],
        paragraph2:"Notre équipe d'experts en informatique est disponible pour répondre à vos besoins en matière de support technique, de gestion des utilisateurs, de sauvegarde des données, de gestion des licences logicielles et de gestion des incidents, vous permettant de vous concentrer sur le développement de votre activité en toute sérénité."
    },
    {
        icon:"fa-brands fa-connectdevelop",
        slug:"integration-erp", 
        titleU:"Intégration ", 
        titleD:"D'ERP", 
        text:"Gérez efficacement vos finances, votre chaîne d'approvisionnement, vos relations clients et d'autres processus métier critiques.",
        img: integration_erp_img,
        paragraph:["Notre offre de service de déploiement d'ERP comprend une approche complète pour la mise en place et la configuration d'un système ERP personnalisé pour répondre aux besoins spécifiques de votre entreprise. Nous commençons par une analyse approfondie de vos processus métier et de vos exigences fonctionnelles pour concevoir et mettre en œuvre une solution ERP adaptée à votre activité.","Nous assurons le déploiement professionnel de l'ERP, en installant et configurant le logiciel, en intégrant les différentes applications et en formant vos équipes à l'utilisation du système. Nous veillons à ce que le processus de déploiement se déroule en douceur et que le système ERP soit opérationnel dans les délais convenus.","Une fois l'ERP installé, notre équipe assure un suivi continu pour garantir le bon fonctionnement du système, en fournissant un support technique, en réalisant des mises à jour régulières, en optimisant les performances et en assurant la formation continue de vos collaborateurs."],
        paragraph2:"En choisissant notre offre de service pour une solution de déploiement d'ERP et le suivi après installation, votre entreprise bénéficiera d'un système ERP efficace et adapté à vos besoins, vous permettant d'optimiser vos processus métier, d'améliorer la productivité de vos équipes et de prendre des décisions stratégiques éclairées pour la croissance de votre entreprise."
    },
]


export const testimonialsArray =  [
    {text:"Notre entreprise a fait appel aux services de Swiftech  pour résoudre des problèmes informatiques complexes. Nous avons été impressionnés par leur expertise et leur professionnalisme. Grâce à eux, nos systèmes fonctionnent à nouveau de manière optimale. Nous recommandons vivement leurs services.", name:"Djeuko Pamela", identity:"Responsable Informatique.", img: userP},
    {text:"Nous avons fait appel à Swiftech pour une assistance informatique et nous sommes très satisfaits de leur travail. Ils ont su résoudre nos problèmes rapidement et efficacement, et leur service clientèle est impeccable. Nous n'hésiterons pas à les contacter à nouveau pour nos besoins futurs.", name:"Pierre Brewster", identity:"Responsable Ressources Humaines", img: userP},
    {text:"Nous sommes extrêmement satisfaits des services fournis par Swiftech. Leur équipe a su comprendre nos besoins spécifiques et nous fournir des solutions personnalisées. Grâce à eux, notre entreprise fonctionne de manière beaucoup plus fluide et nous n'hésiterons pas à faire appel à eux à l'avenir.", name:"Yonga Aurélie", identity:"Chef d’entreprise", img: userP},
]