import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { servicesArray } from '../constant/arrays';
import { email, localisation, localisationLink, phoneNumber, phoneNumberFormat } from '../constant/const';
import ServiceCardsPart from './ServiceCardsPart';

const Services = () => {

    const location = useLocation();
    useEffect(() => {
        if (window.innerWidth < 768) {
            window.scrollTo({top: 0, left: 0, behavior: 'instant' });
        }
    }, [location]);


    const { hash } = useLocation()
    const slug = hash.slice(1)

    const [service, setService] = useState(servicesArray[0])

    useEffect(()=>{
        if (!slug) {
            setService(servicesArray[0])
        } else {
            const test = servicesArray.find(elt => elt.slug === slug)
            setService(test?test:servicesArray[0])
        }
    },[slug])

    
    return (
        <>
        
            {/* Hero */}
            <div className=' hero-service'>
                <div className=' container position-relative z-2'>
                    <div className=' d-flex flex-column justify-content-center align-items-start' style={{minHeight:"60vh"}}>
                        {/* <div className=' bg-black bg-opacity-50 px-4 py-2 text-white fw-medium'>
                            <NavLink to={"/"} className="text-white">Accueil</NavLink>
                            <span> / Services</span>
                        </div> */}
                        <h2 className=' mt-3 fw-bold text-white fs-1'> {service.titleU + service.titleD} </h2>
                    </div>
                </div>
            </div>




            {/* Body */}
            <div className=' container my-5 py-3'>
                <div className=' row justify-content-center align-items-start'>
                    <div  data-aos="zoom-out-right" className=' col-lg-4 col-md-6 col-12'>
                        <div>
                            <div className=' bg-light-gray p-3'>
                                <h3 className=' text-black text-opacity-75'>Services</h3>
                                {
                                    servicesArray.map((elt,index) =>(
                                        <NavLink key={index} to={"#"+elt.slug} className={"row justify-content-between text-black service-detail-link g-0 " +(elt.slug === service.slug? "actif":"")} >
                                            <span className=' col align-items-stretch ps-2 pe-1 py-3'>{elt.titleU + " " + elt.titleD}</span>
                                            <span className='col-auto align-items-stretch'>
                                                <div className=' h-100 px-3 d-flex justify-content-center align-items-center'>
                                                    <i className=' fa fa-arrow-right '></i>
                                                </div>
                                            </span>
                                        </NavLink>
                                    ))
                                }
                            </div>

                            <div className=' service-contact px-md-4 px-4 py-5 mt-4 text-white'>
                                <div className=' position-relative z-2'>
                                    <h2 className=' fw-bold mb-4'>Un souci? Veuillez nous contacter.</h2>

                                    <div className=' d-flex justify-content-start align-items-center mb-3'>
                                        <div className=' bg-black bg-opacity-50 rounded-circle d-flex justify-content-center align-items-center me-3' style={{width:"50px",height:"50px"}}>
                                            <i className=' ri-phone-line fs-3'></i>
                                        </div>
                                        <div>
                                            <small className=' text-white'>Nous appeler</small>
                                            <a className='text-white fw-bold d-block' href={"tel:"+phoneNumber}>{phoneNumberFormat}</a>
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-start align-items-center mb-3'>
                                        <div className=' bg-black bg-opacity-50 rounded-circle d-flex justify-content-center align-items-center me-3' style={{width:"50px",height:"50px"}}>
                                            <i className='ri-mail-line fs-3'></i>
                                        </div>
                                        <div>
                                            <small className=' text-white'>Nous écrire</small>
                                            <a className='text-white fw-bold d-block'href={'mailto:'+email}>{email}</a>
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-start align-items-center mb-3'>
                                        <div className=' bg-black bg-opacity-50 rounded-circle d-flex justify-content-center align-items-center me-3' style={{width:"50px",height:"50px"}}>
                                            <i className='ri-map-pin-2-line fs-3'></i>
                                        </div>
                                        <div>
                                            <small className=' text-white'>Notre addresse</small>
                                            <a className='text-white fw-bold d-block'  target='_BLANK' rel='noreferrer' href={localisationLink}>{localisation}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className=' col-lg-8 col-md-6 col-12' id={service.slug}>
                        <div className=' px-2 mt-md-0 mt-4'>
                            <div className=' d-flex justify-content-center align-items-center w-100 overflow-hidden' style={{maxHeight:"70vh"}}>
                                <img className=' w-100 h-100 object-fit-cover' alt={service.titleU + " " + service.titleD} src={service.img} />
                            </div>
                            <h2 className=' fs-3 fw-bold mt-4'> {service.titleU + " " + service.titleD} </h2>
                            {
                                service.paragraph.map((elt,index) =>(
                                    <p  data-aos="fade-up" data-aos-duration="800" key={index} className=' text-justify lh-lg fw-normal text-black text-opacity-75 mb-1'> {elt} </p>
                                ))
                            }
                            <p  data-aos="fade-up" data-aos-duration="800" className=' text-justify lh-lg fw-normal text-black text-opacity-75 mt-4'> {service.paragraph2} </p>
                        </div>
                    </div>
                </div>
            </div>




            
            {/* SERVICES */}
            <ServiceCardsPart/>


        </>
    )
}

export default Services
