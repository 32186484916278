import React from 'react'

const Title = (props) => {
    return (
        <div className=' text-center mx-auto' style={{maxWidth:"600px"}}>
            <h1 className=' mb-3 fw-bold'> {props.title} </h1>
            <p className=' text-black-50 fw-medium'> {props.subtitle} </p>
        </div>
    )
}

export default Title
