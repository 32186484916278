import Aos from 'aos'
import React, { useEffect } from 'react'

const TestimonialCard = (props) => {
    useEffect(()=>{
        Aos.init()
      },[])
    return (
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 col-lg-4 mb-5">
            <div className="testimonial-single shadow-sm gray-light-bg rounded p-4">
                <blockquote className=' mb-0'>
                    {props.text}
                </blockquote>
            </div>
            <div className="d-flex align-items-center mt-4 pt-2">
                <div className="client-img me-3">
                    <img src={props.img} alt="client" width="60" className="img-fluid rounded-circle shadow-sm"/>
                </div>
                <div className="">
                    <h5 className="mb-0">{props.name}</h5>
                    <p className="mb-0">{props.identity}</p>
                </div>
            </div>
        </div>
    )
}

export default TestimonialCard
