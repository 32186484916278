import logo from '../asset/image/logo_swiftech.png'
import logoS from '../asset/image/logo_swiftech_short.png'
import heroVid from '../asset/video/hero_vid.mp4'
import serviceS from '../asset/image/service-shape-1.png'
import dotS from '../asset/image/dots-shape.png'
import aboutI from '../asset/image/about.jpg'
import ctaI from '../asset/image/home-cta-bg.jpg'
import client1 from '../asset/image/client1.jpg'
import client2 from '../asset/image/client2.avif'
import client3 from '../asset/image/client3.jpg'
import ngomsi from '../asset/image/NgomsiNolan.jpg'
import ntoko from '../asset/image/CharlesNtoko.jpg'
import matouke from '../asset/image/MatoukeThomas.jpg'
import user_profile from '../asset/image/user.webp'
import securite_informatique from '../asset/image/service-securite-info.jpg'
import interconnexion_site_distant from '../asset/image/service-interconnexion-site-distant.jpg'
import reseau_informatique from '../asset/image/service-reseau-informatique.jpg'
import acces_internet from '../asset/image/service-access-internet.jpg'
import solution_cloud from '../asset/image/service-solution-cloud.avif'
import communication_entreprise from '../asset/image/service-communication-entreprise.jpg'
import video_surveillance from '../asset/image/service-video-surveillance.jpg'
import maintenance_infogerance from '../asset/image/service-maintenance.jpg'
import integration_erp from '../asset/image/service-integration-erp.jpg'
import descript1 from '../asset/image/mod2.png'
import descript2 from '../asset/image/mod1.jpg'

export const logo_swiftech = logo 
export const logo_swiftech_short = logoS
export const hero_vid = heroVid 
export const service_shape = serviceS 
export const dot_shape = dotS 
export const about_img = aboutI 
export const home_cta_bg = ctaI 
export const client_1 = client1
export const client_2 = client2
export const client_3 = client3
export const ngomsi_img = ngomsi
export const ntoko_img = ntoko
export const matouke_img = matouke
export const userP = user_profile
export const description_1 = descript1
export const description_2 = descript2

// services imgs
export const securute_informatique_img = securite_informatique
export const interconnexion_site_distant_img = interconnexion_site_distant
export const reseau_informatique_img = reseau_informatique
export const acces_internet_img = acces_internet
export const solution_cloud_img = solution_cloud
export const communication_entreprise_img = communication_entreprise
export const video_surveillance_img = video_surveillance
export const maintenance_infogerance_img = maintenance_infogerance
export const integration_erp_img = integration_erp