import React from 'react'
import Title from '../components/Title'
import { servicesArray } from '../constant/arrays'
import { NavLink } from 'react-router-dom'
import { service_shape } from '../constant/files'


const ServiceCard = (props) =>{
    return(
        <NavLink to={props.link} onClick={()=>window.scrollTo({top: 0, left: 0, behavior: 'instant' })} className="col-lg-4 col-md-6 align-items-stretch mb-4 text-black">
            <div data-aos="fade-up" data-aos-duration="800" className=" bg-white position-relative pt-4 px-5 pb-4 overflow-hidden shadow service-card h-100">
                <div className="service-icon">
                    <i className={props.icon}></i>
                </div>
                <div className="tp-feature-content">
                    <h4 className=" mb-3 fw-medium">{props.titleU} <br/> {props.titleD}</h4>
                    <p>{props.text}</p>
                </div>
                <div className="service-shape">
                    <img src={service_shape} alt="shape" className=' w-100 object-fit-cover'/>
                </div>
            </div>
        </NavLink>
    )
}


const ServiceCardsPart = () => {
    return (
        <div className='bg-light-gray py-5'>
                <div className=' container mb-5 pt-5 pb-4'>
                    <Title title="Transformez votre entreprise" subtitle="Bénéficiez d'une expertise technologique à la pointe du progrès pour vous démarquer." />
                    <div className=' mt-4 row justify-content-around'>
                        {
                            servicesArray.map((elt,index) =>(
                                <ServiceCard  key={index} icon={elt.icon} text={elt.text} titleU={elt.titleU} titleD={elt.titleD} link={"/service#"+elt.slug} />
                            ))
                        }
                    </div>
                </div>
            </div>
    )
}

export default ServiceCardsPart
