import React from 'react'
import { email, localisation, localisationLink, phoneNumber, phoneNumberFormat } from '../constant/const'
import Title from '../components/Title'

const Contact = () => {
    return (
        <div>
            {/* Hero */}
            <div className=' hero-service hero-contact'>
                <div className=' container position-relative z-2'>
                    <div className=' d-flex flex-column justify-content-center align-items-start' style={{minHeight:"60vh"}}>
                        {/* <div className=' bg-black bg-opacity-50 px-4 py-2 text-white fw-medium'>
                            <NavLink to={"/"} className="text-white">Accueil</NavLink>
                            <span> / Contact</span>
                        </div> */}
                        <h2 className=' mt-3 fw-bold text-white fs-1'> Contactez Nous </h2>
                    </div>
                </div>
            </div>

            <div className="contact-information">
                <div className="container">
                    <div className="row justify-content-center">
                        <div data-aos="zoom-in" data-aos-duration="700" className="col-md-4 mb-3">
                            <div className="contact-item">
                                <i className="fa fa-phone"></i>
                                <h4>Téléphone</h4>
                                <a className=' fs-5' href={"tel:"+phoneNumber}>{phoneNumberFormat}</a>
                            </div>
                        </div>
                        <div data-aos="zoom-in" data-aos-duration="700" className="col-md-4 mb-3">
                            <div className="contact-item">
                                <i className="fa fa-envelope"></i>
                                <h4>Email</h4>
                                <a  className=' fs-5' href={"mailto:"+email}>{email}</a>
                            </div>
                        </div>
                        <div data-aos="zoom-in" data-aos-duration="700" className="col-md-4 mb-3">
                            <div className="contact-item">
                                <i className="fa fa-map-marker"></i>
                                <h4>Localisation</h4>
                                <a  className=' fs-5' href={localisationLink}>Ouvrir dans Google Maps</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="callback-form contact-us bg-light-gray py-5">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div data-aos="fade-up" data-aos-duration="700" className="col-md-12 mb-4">
                            <Title title="Une question ? Demandez-nous !" subtitle="Vous avez une question, besoin d'aide ou souhaitez partager un commentaire ? Nous sommes à votre écoute pour vous aider." />
                        </div>
                        <div className="col-md-12 il">
                            <div className="contact-form">
                                <form id="contact">
                                    <div className="row justify-content-center">
                                        <div className=' col-md-6 col-12 row justify-content-center'>
                                            <div data-aos-duration="700" data-aos="fade-right" className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                                <fieldset>
                                                    <input name="name" type="text" className="form-control" id="name" placeholder="Entrez votre nom" required=""/>
                                                </fieldset>
                                            </div>
                                            <div data-aos-duration="700" data-aos="fade-right" className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                            <fieldset>
                                                <input name="email" type="text" className="form-control" id="email" pattern="[^ @]*@[^ @]*" placeholder="Entrez votre e-mail" required=""/>
                                            </fieldset>
                                            </div>
                                            <div data-aos-duration="700" data-aos="fade-right" className="col-md-12 col-sm-12 mb-3">
                                            <fieldset>
                                                <input name="subject" type="text" className="form-control" id="subject" placeholder="Entrez le sujet" required=""/>
                                            </fieldset>
                                            </div>
                                            <div data-aos-duration="700" data-aos="fade-right" className="col-lg-12 text mb-3">
                                                <fieldset>
                                                <textarea name="message" rows="10" className="form-control" id="message" placeholder="Entrez votre message" required=""></textarea>
                                                </fieldset>
                                            </div>
                                            <div data-aos-duration="700" data-aos="fade-right" className="col-lg-12">
                                                <fieldset>
                                                    <button type="submit" id="form-submit" className="filled-button rounded-3">Envoyer</button>
                                                </fieldset>
                                            </div>
                                        </div>
                                        <div className=' col-md-6 col-12 mb-3'>
                                            <div className=' bordercolor-primary p-2'>
                                                <iframe title={localisation} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.8281221826405!2d9.698088874748239!3d4.055447495918308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x106112609e26ebc3%3A0xfcb11e09c5d71a17!2sRue%20Drouot%2C%20Douala!5e0!3m2!1sfr!2scm!4v1714933676025!5m2!1sfr!2scm" width="100%" height="450" style={{border:"0" }}allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact