import React, { useEffect } from 'react'
import { about_img, hero_vid } from '../constant/files'
import Typewriter from 'typewriter-effect/dist/core';
import { NavLink } from 'react-router-dom';
import Title from '../components/Title';
import { testimonialsArray } from '../constant/arrays';
import TestimonialCard from '../components/TestimonialCard';
import Carousel from 'react-multi-carousel';
import ServiceCardsPart from './ServiceCardsPart';


const Home = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    
    useEffect(()=>{
        var app = document.getElementById('app');
        var typewriter = new Typewriter(app, {
            loop: true,
            delay: 0,
        });

        typewriter
            .pauseFor(100)
            .typeString('De Sécurité Informatique')
            .pauseFor(2500)
            .deleteChars(23)
            .typeString("'Interconnexion De Sites Distants")
            .pauseFor(2500)
            .deleteChars(33)
            .typeString('e Réseaux Informatique')
            .pauseFor(2500)
            .deleteChars(20)
            .typeString("Fourniture D'Accès Internet")
            .pauseFor(2500)
            .deleteChars(27)
            .typeString('Solutions Cloud')
            .pauseFor(2500)
            .deleteChars(15)
            .typeString("Communication D'Entreprise")
            .pauseFor(2500)
            .deleteChars(26)
            .typeString('Vidéo Surveillance')
            .pauseFor(2500)
            .deleteChars(18)
            .typeString('Maintenance Et Infogérance')
            .pauseFor(2500)
            .deleteChars(28)
            .typeString("'Intégration D'ERP")
            .pauseFor(2500)
            .deleteChars(19)
            .pauseFor(1000)
            .start();
    },[])


    return (
        <div className=' overflow-x-hidden'>
            {/* Hero */}
            <div className=' position-relative'>
                <video loop muted controls={false} autoPlay className=' w-100 h-100 object-fit-cover' style={{minHeight:'80vh',maxHeight:"100vh"}} >
                    <source src={hero_vid} ></source>
                </video>
                <div className=' position-absolute top-0  bottom-0 end-0 start-0 bgcolor-primary-50'>
                    <div className=' container h-100'>
                        <div className=' h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                            <h1 className=' fw-bold text-white text-uppercase hero-title text-center'>votre agence <span id='app'></span> </h1>
                            
                            <h5 className=' text-white fw-medium text-center mt-5' style={{maxWidth:"700px"}}>Swiftech vous propose une large gamme de service pour resoudre vos differents problemes dans le domaine de L'IT.</h5>

                            <div className=' mt-3 text-center'>
                                <NavLink to={'/service/'} className=" bg-white fw-medium py-2 px-4 rounded-pill color-primary fs-5 d-flex align-items-center">
                                    <span> En savoir plus</span>
                                    <i className=" fa fa-arrow-right ms-2"></i>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* SERVICES */}
            <ServiceCardsPart/>



            {/* ABOUT */}
            <div className=' container mb-5 pt-5 pb-4'>
                <div className=' row justify-content-center align-items-start '>
                    <div data-aos="zoom-in-down" className=' col-md-5 col-12 position-relative mb-4'>
                        <img alt='About swiftech' src={about_img} className=' img-fluid' style={{marginTop:"-90px"}} />
                    </div>
                    <div className=' col-md-7 col-12 bg-white z-2'>
                        <h2 className=' fw-bold'>SWIFTECH: Rencontrez des gens qui font la différence</h2>
                        <span className=' text-black text-opacity-75'>Chez Swiftech, nous cultivons des valeurs qui façonnent notre façon de travailler et d'interagir avec le monde.</span>
                        <div className=' mt-4 row justify-content-around align-items-start'>
                            <div className='col-auto mt-2'>
                                <i className=' fa fa-rocket color-primary fs-1'></i>
                            </div>
                            <div className='col'>
                                <h3 className=' mb-0 fw-bold'>Notre mission</h3>
                                <p>Assurer la sécurité, la connectivité et la performance des systèmes informatiques des entreprises pour leur permettre de prospérer dans un monde numérique en constante évolution.</p>
                            </div>
                        </div>
                        <div className=' mt-2 row justify-content-around align-items-start'>
                            <div className='col-auto mt-2'>
                                <i className=' fa fa-gem color-primary fs-1'></i>
                            </div>
                            <div className='col'>
                                <h3 className=' mb-0 fw-bold'>Nos Valeurs</h3>
                                <p className=' d-flex justify-content-start align-items-start mt-1'>
                                    <i className=' fa fa-angles-right color-primary mt-1 me-2'></i>
                                    <span> <strong className=' text-black'>Excellence: </strong>Délivrer des services de haute qualité et surpasser les attentes des clients.</span>
                                </p>
                                <p className=' d-flex justify-content-start align-items-start mt-1'>
                                    <i className=' fa fa-angles-right color-primary mt-1 me-2'></i>
                                    <span> <strong className=' text-black'>Innovation: </strong>Rester à la pointe du progrès technologique et explorer de nouvelles solutions. </span>
                                </p>
                                <p className=' d-flex justify-content-start align-items-start mt-1'>
                                    <i className=' fa fa-angles-right color-primary mt-1 me-2'></i>
                                    <span> <strong className=' text-black'>Respect: </strong>Traiter clients, partenaires et employés avec respect et considération. </span>
                                </p>
                                <p className=' d-flex justify-content-start align-items-start mt-1'>
                                    <i className=' fa fa-angles-right color-primary mt-1 me-2'></i>
                                    <span> <strong className=' text-black'>Engagement: </strong>Être passionné par le travail et dédié à la réussite des clients. </span>
                                </p>
                            </div>
                        </div>
                        <div className=' text-end d-flex justify-content-end mt-3'>
                            <NavLink to={'/about'} className=" bgcolor-primary fw-medium py-2 px-4 rounded-pill fs-5 d-flex align-items-center w-auto text-white">
                                <span> En savoir plus</span>
                                <i className=" fa fa-arrow-right ms-2"></i>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>



            {/* STATS */}
            <div className='home-stat my-5'>
                <div className=' container py-5 my-5 text-white position-relative z-3'>
                    <div className=' row justify-content-around align-items-center'>
                        <div  data-aos="fade-right" data-aos-duration="800" className=' col-lg-3 col-md-6 col-12 mt-4'>
                            <div className=' text-center'>
                                <i className=' fa-regular fa-clock mb-3' style={{fontSize:"calc(2.875rem + 1.5vw)"}}></i>
                                <span className=' fw-bold fs-3 d-block mb-0 lh-sm'>1000</span>
                                <span className=''>Heures de travail</span>
                            </div>
                        </div>
                        <div  data-aos="fade-right" data-aos-duration="800" className=' col-lg-3 col-md-6 col-12 mt-4'>
                            <div className=' text-center'>
                                <i className=' fa fa-users mb-3' style={{fontSize:"calc(2.875rem + 1.5vw)"}}></i>
                                <span className=' fw-bold fs-3 d-block mb-0 lh-sm'>13</span>
                                <span className=''>Employés</span>
                            </div>
                        </div>
                        <div  data-aos="fade-right" data-aos-duration="800" className=' col-lg-3 col-md-6 col-12 mt-4'>
                            <div className=' text-center'>
                                <i className=' fa fa-lightbulb mb-3' style={{fontSize:"calc(2.875rem + 1.5vw)"}}></i>
                                <span className=' fw-bold fs-3 d-block mb-0 lh-sm'>10</span>
                                <span className=''>Projets</span>
                            </div>
                        </div>
                        <div  data-aos="fade-right" data-aos-duration="800" className=' col-lg-3 col-md-6 col-12 mt-4'>
                            <div className=' text-center'>
                                <i className=' fa fa-hands-holding-circle mb-3' style={{fontSize:"calc(2.875rem + 1.5vw)"}}></i>
                                <span className=' fw-bold fs-3 d-block mb-0 lh-sm'>8</span>
                                <span className=''>Solutions</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* TESTIMONIALS */}
            <div className=' mt-4 pt-5 container mb-5 pb-5'>
                <Title center={true} title={"La Voix des Clients : Découvrez Ce Qu'ils Disent"} subtitle={"Des clients satisfaits sont notre meilleure publicité."} />
                <div className="row mt-4 pt-3 d-md-flex d-none">
                    {
                        testimonialsArray.map((elt, index)=>(
                            <TestimonialCard key={index} text={elt.text} name={elt.name} identity={elt.identity} img={elt.img} />
                        ))
                    }
                </div>

                <div className=' mx-auto d-md-none'>
                    <Carousel className=' py-5' responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={5000} >
                        {
                            testimonialsArray.map((elt, index)=>(
                                <TestimonialCard key={index} text={elt.text} name={elt.name} identity={elt.identity} img={elt.img} />
                            ))
                        }
                    </Carousel>
                </div>
            </div>



            
            {/* CTA */}
            <div className='home-cta mt-5'>
                <div className=' container py-5 mt-5 text-white position-relative z-3'>
                    <div className=' row justify-content-around align-items-center'>
                        <div className=' col-md-8 col-12'>
                            <h2 className=' fw-bold fs-1'>Besoin d'aide ? Contactez nos experts dès aujourd'hui !</h2>
                            <span>Vous avez des questions sur nos services ou souhaitez obtenir un devis ? N'hésitez pas à nous contacter. Nos experts sont à votre disposition pour vous accompagner dans vos projets technologiques.</span>
                        </div>
                        <div className=' col-md-4 col-12'>
                            <div className=' text-end d-flex justify-content-end mt-3'>
                                <NavLink to={'/about'} className=" color-primary bg-white fw-medium py-2 px-4 rounded-pill fs-5 d-flex align-items-center w-auto text-nowrap">
                                    NOUS CONTACTER
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default Home
